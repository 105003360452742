import { createSlice } from '@reduxjs/toolkit';
import { PlanningColumnsKeys } from 'modules/inbound/shared/domain/ColumnsTypes';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import CoverageAtRisk from 'modules/shared/domain/CoverageAtRisk';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import {
  downloadDirectDelivery,
  getCoverageAtRisk,
  getDirectDeliveryData
} from './async';
import { BarStatus, DirectDeliveryState } from './types';
import { getLocalStorage } from 'modules/shared/utils/getLocalStorage';
import { getColumnsFromLocalStorage } from 'modules/inbound/shared/utils/getColumnsFromLocalStorage';
import { DIRECT_DELIVERY_COLUMNS } from 'modules/inbound/directDelivery/domain/DirectDeliveryColumns';

export const initialState: DirectDeliveryState = {
  data: {
    stockUpdatedAt: '',
    assemblyNeedsUpdatedAt: '',
    bodyworkNeedsUpdatedAt: '',
    planningDates: [],
    directDeliveries: [],
    coverageAtRisk: CoverageAtRisk.generateEmpty(),
    uids: [],
    providerIds: []
  },
  columns: DIRECT_DELIVERY_COLUMNS,
  planningColumns: [],
  savedColumns: [],
  requestStatus: '',
  requestCoverageAtRiskStatus: '',
  selectedColumn: {
    key: '',
    isAscending: false
  },
  pagination: {
    page: 1,
    size: 50,
    pageCount: 0,
    totalCount: 0
  },
  selectedDays: 1,
  downloadRequestStatus: '',
  directDeliveryFile: null
};

const DIRECT_DELIVERY_COLUMNS_VIEW_STATE_KEY = 'DirectDeliveryColumnsViewState';
const DIRECT_DELIVERY_NEEDS_DAYS_NUMBER = 'DirectDeliveryNumberOfNeedsDays';
const DIRECT_DELIVERIES = 'direct-deliveries';

const directDeliverySlice = createSlice({
  name: 'directDeliveryData',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    updateSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
    updateVisibleColumns: (state, action) => {
      state.savedColumns = state.savedColumns.map((item) =>
        item.key === action.payload.key
          ? { ...item, isVisibleCheck: !action.payload.isVisibleCheck }
          : item
      );
    },
    updateSelectedDays: (state, action) => {
      state.selectedDays = action.payload;
    },
    updateColumnsList: (state, action) => {
      state.savedColumns = action.payload;
    },
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    },
    saveColumnsView: (state, action) => {
      localStorage.setItem(
        `${action.payload}-${DIRECT_DELIVERY_COLUMNS_VIEW_STATE_KEY}`,
        JSON.stringify(state.savedColumns)
      );
      localStorage.setItem(
        `${action.payload}-${DIRECT_DELIVERY_NEEDS_DAYS_NUMBER}`,
        JSON.stringify(state.selectedDays)
      );
    },
    loadColumnsView: (state, action) => {
      state.selectedDays = getLocalStorage<number>(
        `${action.payload}-${DIRECT_DELIVERY_NEEDS_DAYS_NUMBER}`,
        1
      );

      state.savedColumns = getColumnsFromLocalStorage({
        username: action.payload,
        localStorageKey: DIRECT_DELIVERY_COLUMNS_VIEW_STATE_KEY,
        defaultColumns: [...state.columns]
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDirectDeliveryData.fulfilled, (state, action) => {
      state.data.stockUpdatedAt = action.payload.stockUpdatedAt;
      state.data.assemblyNeedsUpdatedAt = action.payload.assemblyNeedsUpdatedAt;
      state.data.bodyworkNeedsUpdatedAt = action.payload.bodyworkNeedsUpdatedAt;
      state.data.planningDates = action.payload.planningDates;
      state.data.directDeliveries = action.payload.directDeliveries;
      state.planningColumns = action.payload.planningColumns;
      state.pagination = action.payload.pagination;
      state.data.uids = action.payload.targetUids;
      state.data.providerIds = action.payload.targetProviderIds;
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getDirectDeliveryData.pending, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getDirectDeliveryData.rejected, (state, action) => {
      state.requestStatus = action.meta.requestStatus;
    });
    builder.addCase(getCoverageAtRisk.fulfilled, (state, action) => {
      state.data.coverageAtRisk = action.payload;
      state.requestCoverageAtRiskStatus = action.meta.requestStatus;
    });
    builder.addCase(getCoverageAtRisk.pending, (state, action) => {
      state.requestCoverageAtRiskStatus = action.meta.requestStatus;
    });
    builder.addCase(getCoverageAtRisk.rejected, (state, action) => {
      state.requestCoverageAtRiskStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadDirectDelivery.fulfilled, (state, action) => {
      state.directDeliveryFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: `P_DirectDelivery_${GetDateFormatedService.formatCompleteISODate()}`
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadDirectDelivery.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadDirectDelivery.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
  }
});

//selectors
export const getSelectedDays = (state) =>
  state.directDeliveryState.selectedDays;
export const getDirectDeliveries = (state) =>
  state.directDeliveryState.data.directDeliveries;
export const getColumns = (state) => state.directDeliveryState.savedColumns;
export const getPlanningColumns = (state) =>
  state.directDeliveryState.planningColumns;
export const getPlanningDates = (state) =>
  state.directDeliveryState.data.planningDates;
export const getCheckListColumns = (state) => {
  const columns = getColumns(state);
  if (columns.length) {
    const optionalColumns = columns.filter((column) => {
      if (
        column.key !== PlanningColumnsKeys.alwaysVisible &&
        column.key !== PlanningColumnsKeys.optionVisible
      ) {
        return column;
      }
    });

    return optionalColumns;
  }
  return [];
};
export const getVisiblePlanningDates = (state) => {
  const planningDates = getPlanningDates(state);
  const selectedPlanningDays = getSelectedDays(state);
  if (selectedPlanningDays > 1) {
    return planningDates.slice(0, selectedPlanningDays);
  }
  return planningDates.slice(0, 1);
};
export const getCoverageAtRiskTotalUI = (state) => {
  const totalAtRisk = state.directDeliveryState.data.coverageAtRisk.totalRisk;
  return [
    {
      xAxisLabels: {
        top: totalAtRisk.name,
        bottom: totalAtRisk.total
      },
      stackedBars: totalAtRisk.stats.map((item) => {
        return { number: item.counter, color: BarStatus[item.status] };
      })
    }
  ];
};
export const getCoverageAtRiskTotalScale = (state) => {
  const maxValue =
    Math.ceil(
      state.directDeliveryState.data.coverageAtRisk.totalRisk.total / 7
    ) * 7;
  return {
    totalMaxValue: maxValue,
    totalScale: Array(...Array(8)).map((_, i) => (i * maxValue) / 7)
  };
};
export const getCoverageAtRiskModelsUI = (state) => {
  const modelsAtRisk =
    state.directDeliveryState.data.coverageAtRisk?.modelsRisk?.filter(
      (item) => item.total > 0
    );
  return modelsAtRisk?.map((item) => {
    return {
      xAxisLabels: {
        top: item.name,
        bottom: item.total
      },
      stackedBars: item.stats.map((item) => {
        return { number: item.counter, color: BarStatus[item.status] };
      })
    };
  });
};
export const getCoverageAtRiskModelsScale = (state) => {
  const models = state.directDeliveryState.data.coverageAtRisk.modelsRisk;
  const maxValue = models
    ? Math.ceil(Math.max(...models.map(({ total }) => total)) / 7) * 7
    : 0;
  return {
    modelsMaxValue: maxValue,
    modelsScale: Array(...Array(8)).map((_, i) => (i * maxValue) / 7)
  };
};
export const getStockUpdatedAt = (state) =>
  state.directDeliveryState.data.stockUpdatedAt;
export const getAssemblyNeedsUpdatedAt = (state) =>
  state.directDeliveryState.data.assemblyNeedsUpdatedAt;
export const getBodyworkNeedsUpdatedAt = (state) =>
  state.directDeliveryState.data.bodyworkNeedsUpdatedAt;
export const getisLoadingStatus = (state) =>
  state.directDeliveryState.requestStatus === 'pending';
export const getHasError = (state) =>
  state.directDeliveryState.requestStatus === 'rejected' ||
  !state.directDeliveryState.data.directDeliveries.length;
export const getisLoadingCoverageAtRisk = (state) =>
  state.directDeliveryState.requestCoverageAtRiskStatus === 'pending';
export const getHasErrorCoverageAtRisk = (state) =>
  state.directDeliveryState.requestCoverageAtRiskStatus === 'rejected';
export const getPagination = (state) => state.directDeliveryState.pagination;
export const getSelectedColumn = (state) =>
  state.directDeliveryState.selectedColumn;
export const getIsFulfilledDirectDeliveries = (state) =>
  state.directDeliveryState.requestStatus === 'fulfilled';
export const getTargetTypeAndUids = (state) => {
  return {
    targetType: DIRECT_DELIVERIES,
    targetUids: state.directDeliveryState.data.uids
  };
};
export const getTargetProviderIds = (state) => {
  return state.directDeliveryState.data.providerIds;
};
export const getDownloadDirectDeliveryIsPending = (state) =>
  state.directDeliveryState.downloadRequestStatus === 'pending';
export const getDownloadDDSuccessful = (state) =>
  state.directDeliveryState.downloadRequestStatus === 'fulfilled' &&
  state.directDeliveryState.directDeliveryFile !== null;
export const getDownloadDDError = (state) =>
  state.directDeliveryState.downloadRequestStatus === 'rejected';

export const {
  updateCurrentPage,
  updateSelectedColumn,
  updateSelectedDays,
  updateColumnsList,
  resetDownloadStatus,
  updateVisibleColumns,
  saveColumnsView,
  loadColumnsView
} = directDeliverySlice.actions;

export default directDeliverySlice.reducer;
