import LocaleService from 'infrastructure/i18n/LocaleService';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { NoJitStockDTO } from '../infrastructure/dto/NoJitDataDTO';
import { NoJitTranslations } from '../infrastructure/i18n/typing';
import { NoJitText } from '../infrastructure/i18n/NoJitText';
import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';
import { MovementsLineDTO } from 'modules/inbound/shared/infraestructure/dto/MovementsLineDTO';

const noJitTranslations: InboundTranslations & NoJitTranslations = {
  ...InboundText[LocaleService.getLanguage()],
  ...NoJitText[LocaleService.getLanguage()]
};

class NoJitStock {
  code: string;
  comment: string;
  providerCodes: string;
  createdAt: string;
  name: string;
  providerNames: string[];
  providerIds: string;
  stock: string;
  countries: string;
  inbounders: string;
  tierStock: string;
  tierStockUpdatedAt: string;
  orderCoverage: string;
  securityMargin: string;
  lines: string;
  models: string;
  pastLoads: string[];
  endProductionInDays: string;
  lineMovements: MovementsLineDTO[];
  warehouses: string;
  factoryCoverage: string;
  transitsCoverage: string;

  constructor({
    code,
    comment,
    providerCodes,
    createdAt,
    name,
    providerNames,
    providerIds,
    stock,
    countries,
    inbounders,
    tierStock,
    tierStockUpdatedAt,
    orderCoverage,
    securityMargin,
    lines,
    models,
    pastLoads,
    endProductionInDays,
    lineMovements,
    warehouses,
    factoryCoverage,
    transitsCoverage
  }: NoJitStock) {
    this.code = code;
    this.comment = comment;
    this.providerCodes = providerCodes;
    this.createdAt = createdAt;
    this.name = name;
    this.providerNames = providerNames;
    this.providerIds = providerIds;
    this.stock = stock;
    this.countries = countries;
    this.inbounders = inbounders;
    this.tierStock = tierStock;
    this.tierStockUpdatedAt = tierStockUpdatedAt;
    this.orderCoverage = orderCoverage;
    this.securityMargin = securityMargin;
    this.lines = lines;
    this.models = models;
    this.pastLoads = pastLoads;
    this.endProductionInDays = endProductionInDays;
    this.lineMovements = lineMovements;
    this.warehouses = warehouses;
    this.factoryCoverage = factoryCoverage;
    this.transitsCoverage = transitsCoverage;
  }

  private static _formatThousandsDot(quantity) {
    if (quantity === null) {
      return '-';
    }
    return quantity.toLocaleString('de-DE');
  }

  private static _formatEndOfProductionDays(days) {
    if (days === null) return '-';

    if (days < 0) {
      const expiredDate = new Date();
      expiredDate.setDate(expiredDate.getDate() - Math.abs(days));
      return `${expiredDate.toLocaleDateString()}`;
    }

    if (days > 90) return '> 90d';

    if (days < 91 && days > 59) return '60-90d';

    if (days < 60 && days > 29) return '30-60d';

    return '< 30d';
  }

  private static _removeDuplicatedItems(array) {
    return array.filter(function (item, index) {
      return array.indexOf(item) === index;
    });
  }

  private static _getCoverageAndEndDate(coverage, endDate) {
    return coverage === 'MAX' && endDate === 'MAX'
      ? 'MAX'
      : `${this._formatCoverageDays(
          coverage
        )} (${GetDateFormatedService.getFullDateTimeWithoutYear(endDate)})`;
  }

  private static _formatCoverageDays(coverageDays) {
    return coverageDays === 'MAX'
      ? coverageDays
      : `${coverageDays.replace('.', ',')}${noJitTranslations.unitDay}`;
  }

  private static _formatStockUnits(stock: number): string {
    const { unitSinglePiece, unitMultiplePieces } = noJitTranslations;

    if (stock === 1) {
      return `${this._formatThousandsDot(stock)} ${unitSinglePiece}`;
    }
    return `${this._formatThousandsDot(stock)} ${unitMultiplePieces}`;
  }

  static generateFromDto(stock: NoJitStockDTO): NoJitStock {
    return new NoJitStock({
      code: stock.code,
      comment: stock.comment,
      providerCodes: stock.provider_codes
        ? stock.provider_codes
            .map((code) => code ?? noJitTranslations.withoutReference)
            .join(' | ')
        : noJitTranslations.withoutReference,
      createdAt: stock.created_at,
      name: stock.name,
      providerNames: this._removeDuplicatedItems(stock.provider_names),
      providerIds: this._removeDuplicatedItems(stock.provider_ids).join(' | '),
      stock: this._formatStockUnits(stock.stock),
      countries: this._removeDuplicatedItems(stock.provider_countries).join(
        ' | '
      ),
      inbounders: this._removeDuplicatedItems(stock.provider_inbounders).join(
        ' | '
      ),
      tierStock: this._formatStockUnits(stock.tier_stock),
      tierStockUpdatedAt: stock.tier_stock_updated_at
        ? GetDateFormatedService.getShortDateTime(stock.tier_stock_updated_at)
        : NO_DATA,
      orderCoverage: this._getCoverageAndEndDate(
        stock.margin,
        stock.margin_end_datetime
      ),
      securityMargin: stock.security_margin,
      lines: stock.lines?.replaceAll('|', ' | ') || NO_DATA,
      models: stock.models?.replaceAll('|', ' | ') || NO_DATA,
      pastLoads: [],
      endProductionInDays: this._formatEndOfProductionDays(
        stock.end_production_in_days
      ),
      lineMovements: stock.last_line_movements,
      warehouses: stock.warehouses.length
        ? this._removeDuplicatedItems(stock.warehouses).join(' | ')
        : NO_DATA,
      factoryCoverage: this._getCoverageAndEndDate(
        stock.factory_coverage,
        stock.factory_coverage_end_datetime
      ),
      transitsCoverage: this._getCoverageAndEndDate(
        stock.transit_coverage,
        stock.transit_coverage_end_datetime
      )
    });
  }
}

export { NoJitStock };
